import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blank-layout',
  templateUrl: './blank-layout.component.html',
  styleUrls: ['./blank-layout.component.scss']
})
export class BlankLayoutComponent implements OnInit {
  signInlayout:boolean=false
  constructor() {
    if(localStorage.getItem("demo_login_status")!='undefined' && localStorage.getItem("demo_login_status")!=null){
      this.signInlayout=true
    }else{
      this.signInlayout=false
    }
   }

  ngOnInit() {
  }

}
