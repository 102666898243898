import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor() { }

  getServerBaseURL(): string {
    return 'https://api.bookprintingonline.in/'
    //return 'http://localhost/printster_api/'
  }
  getRemoveURL(): string {
    return '/home/bkpon/api.bookprintingonline.in/'
    //return 'http://localhost/printster_api/'
  }



}
