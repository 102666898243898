import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { InMemoryDataService } from './shared/inmemory-db/inmemory-db.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenizeRequestService } from './shared/services/http/requst_interceptor/tokenize-request.service';
import { UploadFilesComponent } from './views/upload-files/upload-files.component';
import { NumberOnlyDirective } from './directive/number-only.directive';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { SpacialCharNotAllowedDirective } from './directive/spacial-char-not-allowed.directive';
import { PaymentService } from './shared/services/payment.service';
import { WindowRefService } from './shared/window-ref.service';
import {NgxPaginationModule} from 'ngx-pagination';
@NgModule({
  declarations: [
    AppComponent,
    UploadFilesComponent,
    AddressModalComponent,
    
  ],
  imports: [
    BrowserModule,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    InMemoryWebApiModule.forRoot(InMemoryDataService, { passThruUnknownUrl: true }),
    AppRoutingModule,
    NgxPaginationModule  
  ],
  providers: [
    {
			provide: HTTP_INTERCEPTORS,
			useClass: TokenizeRequestService,
			multi: true,
		},
    PaymentService,
    WindowRefService
  ],
  bootstrap: [AppComponent],
  entryComponents:[AddressModalComponent]
})
export class AppModule { }
