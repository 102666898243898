import { Injectable } from "@angular/core";
import { LocalStoreService } from "./local-store.service";
import { Router } from "@angular/router";
import { of } from "rxjs";
import { delay } from "rxjs/operators";
import { ConfigService } from "src/app/config/config.service";
import { HttpService } from "./http/http.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  //Only for demo purpose
  authenticated = false;
  apiUrl=this.configService.getServerBaseURL();
  
  constructor(
    private store: LocalStoreService,
    private router: Router,
    private configService: ConfigService,
    private http: HttpService
    ) {
    this.checkAuth();
  }
  checkAuth() {
    
     this.authenticated = this.store.getItem("demo_login_status");
  }

  getuser() {
    return this.store.getItem("username");
  }
  getPcash(service:any) {
    return this.http.get(service);
  }
  getCart(service:any) {
    return this.http.get(service);
  }

  signin(service:any,data:any) {
   
    return this.http.post(service,data);
  }
  registartion(service:any,data:any) {
    return this.http.post(service,data);
  }
  otp(service:any,data:any) {
    return this.http.post(service,data);
  }
  get_user_otp(service:any,data:any) {
    return this.http.post(service,data);
  }
  signout() {
    this.store.clear()
    this.router.navigateByUrl("/sessions/signin");
  }
  getfiles(service:any) {
    return this.http.get(service);
  }
 postfiles(service:any,data:any) {
    return this.http.post(service,data);
  }
}
