<div class="app-admin-wrap layout-sidebar-large">
   
    <div *ngIf="signInlayout">
        <app-header-sidebar-large></app-header-sidebar-large>
    </div> 
    <div *ngIf="!signInlayout">
    <div class="main-header border-bottom border-primary">
        <div style="display: flex;">
           <div class="ml-2 mr-2  d-none d-md-block d-xl-block"> <button class="btn btn btn-outline-dark"><i class="i-Arrow-Left mr-1"></i> Home</button></div>
       
       <div  ngbDropdown [placement]="'bottom-left'" class=" user " >
              <button class="btn btn-outline-dark dropdown-toggle" href="" id="userDropdown1" ngbDropdownToggle  ><span>&nbsp; Menu &nbsp; &nbsp;</span>
               </button>
                   <div ngbDropdownMenu aria-labelledby="userDropdown1" class="header-account-dropdown moveright">
       
                   <a class="dropdown-item" routerLink="/sessions/how-it-works"><i class="i-Arrow-Right mr-1"></i> How's it Works</a>
                   <a class="dropdown-item" routerLink="/dashboard/products" *ngIf="signInlayout"><i class="i-Arrow-Right mr-1"></i>Products</a>
                   <a class="dropdown-item" routerLink="/others/price-calculator"><i class="i-Arrow-Right mr-1"></i>Price Calculator</a>
                   <a class="dropdown-item" routerLink="/sessions/faq"><i class="i-Arrow-Right mr-1"></i>FAQs</a>
                   <a class="dropdown-item" routerLink="/pages/orders"><i class="i-Arrow-Right mr-1"></i>Offers</a>
       
                   <a class="dropdown-item" routerLink="/pages/pcash" *ngIf="signInlayout"><i class="i-Arrow-Right mr-1"></i>Track Order</a>
       
                   <a class="dropdown-item" routerLink="/pages/orders"><i class="i-Arrow-Right mr-1"></i>Contact Us</a>
       
               </div>
       
           </div>
        <div class="menu-toggle" (click)="toggelSidebar()" >
               <div></div>
               <div></div>
               <div></div>
       
           </div>
       
       </div>
           <div class="logo text-center pl-2 pr-2 d-none d-md-block d-xl-block" style="width: 300px"  >
             <a href="" routerLink="/dashboard/v3" aria-details="Printster.in Logo">  <img src="./assets/images/logo.png" style="width: 300px"></a>
       
           </div>
       
           <div class="logo m-0 p-0 d-block d-sm-none"  style="width: 80px"  >
               <a href="" routerLink="/dashboard/v3">   <img src="./assets/images/logo-fit.png" style="width: 80px" ></a>
       
           </div>
           <div class="header-part-right">
            <div ngbDropdown [placement]="'bottom-right'" class=" user col align-self-end">
                <a class="dropdown-item" routerLink="/"> <i class="i-MaleFemale mr-1"></i>Sign In</a>
            </div>
        </div>
    </div>
</div>
<div class="main-content-wrap d-flex flex-column">
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
    </div>
</div>